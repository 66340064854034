import React from "react"
import { StaticQuery, graphql } from "gatsby"
import isMobile from 'ismobilejs'

import { Parallax, ParallaxBanner, ParallaxProvider} from 'react-scroll-parallax'
import ImageZoom from 'react-medium-image-zoom'

import Layout from "../components/layout"
import FixedViewportHeight from "../components/fixed-viewport-height"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            subtitle,
            section1_title,
            section1_content,
            section1_image1_alt,
            section2_image1_alt,
            section2_content,
            section2_creditcard_title,
            section2_creditcard_content,
            section2_bankcheck_title,
            section2_bankcheck_content,
            section3_content,
            section3_image1_alt,
            nav2,
            nav3
          }
        }
        image1: file(relativePath: { eq: "michel-picaud.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3: file(relativePath: { eq: "notredamedeparis-reconstruction.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image5: file(relativePath: { eq: "notredamedeparis-interieur.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery1_thumb: file(relativePath: { eq: "gallery1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery1: file(relativePath: { eq: "gallery1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery2_thumb: file(relativePath: { eq: "gallery2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery2: file(relativePath: { eq: "gallery2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery3_thumb: file(relativePath: { eq: "gallery3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery3: file(relativePath: { eq: "gallery3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery4_thumb: file(relativePath: { eq: "gallery4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery4: file(relativePath: { eq: "gallery4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery5_thumb: file(relativePath: { eq: "gallery5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery5: file(relativePath: { eq: "gallery5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery6_thumb: file(relativePath: { eq: "gallery6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery6: file(relativePath: { eq: "gallery6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery7_thumb: file(relativePath: { eq: "gallery7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery7: file(relativePath: { eq: "gallery7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery8_thumb: file(relativePath: { eq: "gallery8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery8: file(relativePath: { eq: "gallery8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <ParallaxProvider>
      <Layout>
        <SEO title={data.site.siteMetadata.subtitle} keywords={[`gatsby`, `application`, `react`]}/>
        <section className="content">
          <div className="anchor" id="reconstruction"></div>
          <Parallax y={['0px', '0px']} disabled={isMobile.any}>
            {data.site.siteMetadata.section1_title && (
              <h1>{data.site.siteMetadata.section1_title}</h1>
            )}
            <div className="content">
              <div>
                <p dangerouslySetInnerHTML={{__html: data.site.siteMetadata.section1_content}}/>
              </div>
              <Img
                style={{
                  maxWidth: '420px'
                }}
                fluid={data.image1.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                title={data.site.siteMetadata.section1_image1_alt}
                alt={data.site.siteMetadata.section1_image1_alt}
              />
            </div>
          </Parallax>
        </section>
        <section className="full-image">
          <FixedViewportHeight>
            <ParallaxBanner
              layers={[
                {
                    children: <Img className="carrousel-image" fluid={data.image3.childImageSharp.fluid} style={{position: 'initial'}} objectFit="cover" objectPosition={data.site.siteMetadata.section2_image1_alt} title={data.site.siteMetadata.section2_image1_alt}/>,
                    amount: 0.2
                }
              ]}
              disabled={isMobile.any}
              style={{
                  height: '100%'
              }}
            >
            </ParallaxBanner>
          </FixedViewportHeight>
        </section>
        <section className="content">
          <div className="anchor" id="devenir-mecene"></div>
          <Parallax y={[0, 0]} disabled={isMobile.any}>
            <h1>{data.site.siteMetadata.nav2}</h1>
            <p dangerouslySetInnerHTML={{__html: data.site.siteMetadata.section2_content}}/>
            <div className="payment-methods">
              <div className="sub-content">
                <h2>{data.site.siteMetadata.section2_creditcard_title}</h2>
                <div dangerouslySetInnerHTML={{__html: data.site.siteMetadata.section2_creditcard_content}}/>
              </div>
              <div className="sub-content">
                <h2>{data.site.siteMetadata.section2_bankcheck_title}</h2>
                <p dangerouslySetInnerHTML={{__html: data.site.siteMetadata.section2_bankcheck_content}}/>
              </div>
              <div className="gallery">
                <div className="row">
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery1_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery1.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery2_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery2.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery3_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery3.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery4_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery4.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery5_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery5.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery6_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery6.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery7_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery7.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                  <div>
                    <ImageZoom
                      zoomMargin="0"
                      image={{
                        src: data.gallery8_thumb.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                      zoomImage={{
                        src: data.gallery8.childImageSharp.fluid.src,
                        alt: 'Notre Dame de Paris'
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </section>
        <section className="full-image">
          <FixedViewportHeight>
            <ParallaxBanner
              layers={[
                {
                    children: <Img className="carrousel-image" fluid={data.image5.childImageSharp.fluid} style={{position: 'initial'}} objectFit="cover" objectPosition="50% 50%" title={data.site.siteMetadata.section3_image1_alt} alt={data.site.siteMetadata.section3_image1_alt}/>,
                    amount: 0.2
                }
              ]}
              disabled={isMobile.any}
              style={{
                  height: '100%'
              }}
            >
            </ParallaxBanner>
          </FixedViewportHeight>
        </section>
        <section className="content">
          <div className="anchor" id="contact"></div>
            <Parallax y={[0, 0]} disabled={isMobile.any}>
              <h1>Contact</h1>
              <p dangerouslySetInnerHTML={{__html: data.site.siteMetadata.section3_content}}/>
            </Parallax>
        </section>
      </Layout>
    </ParallaxProvider>
    )}
  />
)
